.e-customerFirstPrize {
    .ant-upload-list-item-container {
        display: none;
    }

    &-errorText {
        margin-top: rem(8);
        color: $red-orange;
    }

    &-modal {
        width: 40% !important;
    }
}
