/* stylelint-disable selector-no-qualifying-type */
.tfc {
    &-table {
        th.ant-table-cell {
            white-space: nowrap;
            text-transform: uppercase;
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        td.ant-table-cell {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        &.sms-gateway-mts-table {
            .ant-table {
                margin: 0 !important;
            }
        }
    }

    &-btnIcon_link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: inherit;
        background-color: $white;
        border: 1px solid $gainsboro;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
        border-radius: 2px;

        &:hover {
            color: inherit !important;
        }
    }
}
