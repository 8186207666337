.o-convertReward {
    $root: &;
    display: flex;
    padding: rem(12) rem(26);
    flex-direction: column;
    align-items: center;
    gap: rem(20);
    width: 100%;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    height: 100%;

    &-hiddenConfirmed {
        #{$root}_content_item {
            width: 50%;

            &:first-child {
                background-color: $brand-color2;
            }
        }
    }

    &_titleWrapper {
        text-align: center;
    }

    &_title {
        color: #015714;
        text-align: center;
        font-size: rem(16);
        font-style: normal;
        font-weight: 500;
        line-height: rem(20);
        letter-spacing: 0.184px;
        text-transform: uppercase;

        &-first {
            color: $persian-blue !important;
        }

        &-special {
            color: $brand-color3 !important;
        }

        &-topup {
            color: $may-green !important;
        }
    }

    &_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // align-items: stretch;
        flex: 1;
        gap: rem(20);

        &_item {
            padding: 12px;
            text-align: center;
            border-radius: 2px;
            width: calc(100%/3);
            background-color: $brand-color2;

            &:first-child {
                background-color: $brand-color3;
            }

            &:last-child {
                background-color: #df15c1;
            }

            &_wrapper {
                :first-child {
                    flex: 1;
                }
            }

            &-heading,
            &-value {
                color: $white !important;
                text-align: center;
                font-weight: 500;
            }

            &-heading {
                font-size: rem(16);
                line-height: rem(20);
                flex: 1;
            }

            &-value {
                font-size: rem(24);
                line-height: rem(24);
            }
        }
    }

    &_awardRate {
        padding: 12px;
        text-align: center;
        border-radius: 2px;
        width: 100%;
        background-color: $pearl-aqua;
    }
}
