.attemptList {
    $root: &;

    .ant-space-item {
        position: relative;

        &::before {
            content: unset;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            height: rem(50);
            width: rem(2);
            border-left: 2px dashed #c9c3c3bd;
        }

        &:not(:first-child) {
            margin-top: rem(50);
        } 
        
        &:last-child {
            &::before {
                content: unset;
            }
        }
    }

    &-hasLine {
        .ant-space-item {
            &::before {
                content: '';
            }
        }
    }
}
