.t-mainlayout {
    &_sidebar {
        &_header {
            align-items: center;
            justify-content: center;
            display: flex;
            height: rem(91);
            padding: 0 rem(20);
            background-color: transparent;

            .ant-image {
                flex-shrink: 0;
            }
        }

        &_branch {
            color: $cool-black;
            font-size: rem(16) !important;
            margin-bottom: 0 !important;
            margin-left: rem(8);
            opacity: 1;
            transition: $transition;

            &-hidden {
                opacity: 0;
                visibility: hidden;
            }
        }

        &_menu {
            // padding-top: rem(16);
            padding-bottom: rem(16);
            height: calc(100% - 6.5rem);
        }
    }

    &_hamburger {
        &:hover,
        &:focus {
            border-color: $cool-black;
            color: $cool-black;
        }
    }

    &_header {
        display: flex;
        justify-content: space-between;

        &_item {
            align-items: center;
            display: flex;

            .ant-space-item {
                align-items: center;
                display: flex;
            }
        }
    }

    &_content {
        padding: 0 0 rem(48);
        height: 100vh;
        max-height: 100vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        @include scrollbars();
    }

    &_wrapper {
        position: relative;
        flex-grow: 1;
        margin-left: rem($main-wrapper-layout);
        margin-right: rem($main-wrapper-layout);
        margin-top: rem(100);

        @include max-desktop-down {
            margin-left: rem($main-wrapper-layout-medium);
            margin-right: rem($main-wrapper-layout-medium);
        }

        @include desktop-down {
            margin-left: rem($main-wrapper-layout-small);
            margin-right: rem($main-wrapper-layout-small);
        }

        .ant-spin-nested-loading,
        .ant-spin-container {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .ant-table-thead {
            .ant-table-cell {
                &.fetchColumn {
                    cursor: pointer;
                    transition: opacity 0.25s ease-in-out;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }        
    }

    &_tab {
        &_pagination {
            width: fit-content;
            margin-left: auto;
            margin-top: rem(16);
        }
    }

    &_table {
        height: rem(504);
        overflow: auto;
        @include scrollbars;
  
        &_text {
            text-align: center;
        }

        .ant-card-head-title {
            text-align: right;
        }
    }

    &_card {
        &:not(:first-child) {
            margin-top: rem(16);
        }

        &_title {
            &:not(:first-child) {
                margin-top: rem(16) !important;
            }
        }

        &_flag {
            position: absolute;
            top: rem(14);
            right: rem(14);
        }
    }
}
