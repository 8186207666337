.o-reportReward {
    width: 100%;

    &_title {
        color: $brand-color2 !important;
        text-align: center;
    }

    &_filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
