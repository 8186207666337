.o-reportSMSBrandName {
    &_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: rem(20);

        &_item {
            padding: rem(12);
            text-align: center;
            border-radius: rem(2);
            width: 100%;
            background-color: $white;
            // border: 1px solid $brand-color;

            &-heading,
            &-value {
                color: $brand-color !important;
                font-weight: 500;
            }

            &-heading {
                font-size: rem(16);
                line-height: rem(20);
            }

            &-value {
                font-size: rem(24);
                line-height: rem(24);
                text-align: center;
            }
        }
    }

    .ant-tooltip {
        .ant-tooltip-inner {
            padding: rem(10) !important;
        }
    }

    &_title {
        display: flex;
        justify-content: space-between;
    }
}
